<script lang="ts">
import { defineComponent } from "vue";
import { useClipboard, usePermission } from "@vueuse/core";

const permissionRead = usePermission("clipboard-read");
const permissionWrite = usePermission("clipboard-write");

export default defineComponent({
  name: "ErrorMessage",
  props: {
    text: { type: String, required: true },
    titolo: { type: String, default: "Error" },
    showError: { type: Boolean, default: true }
  },
  emits: ["clearError"],
  setup () {
    const { copy, copied, legacy } = useClipboard({ legacy: true });

    return {
      copy,
      copied,
      legacy
    };
  },
  data () {
    return {
      dialog: this.showError
    };
  },
  watch: {
    dialog (newvalue, oldvalue) {
      // in caso di chiusura del dialog non viene attraverso il bottone ma cliccando fuori, azzero itemToDelete
      if (!newvalue && oldvalue) {
        // code to be exceuted when dialog model become false
        this.closeDialog();
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false;
      this.$emit("clearError");
    },
    copy2Clipboard () {
      this.copy(this.text);
    }
  }
});
</script>

<template>
  <v-dialog v-model="dialog" width="50%" scrollable>
    <v-card
      color="red"
      prepend-icon="mdi-alert-octagram"
    >
      <template #title>
        {{ titolo }}
      </template>
      <v-divider />
      <template #append>
        <v-icon icon="mdi-close-circle" @click="closeDialog" />
      </template>
      <v-card-text>
        <v-card>
          <v-card-text ref="message">
            {{ text }}
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <div v-if="!copied">
          <v-btn color="white" @click="copy2Clipboard()">
            Copia
          </v-btn>
        </div>
        <div v-else>
          <v-btn color="white" disabled>
            Copiato
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
